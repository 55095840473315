import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import get from "lodash.get";
import { resolveComponentProps } from "@washingtonpost/election-components/dist/utils/resolveComponentProps";
import { parseMapConfig } from "@washingtonpost/elex-utils/dist/map/mapUtils";
import usePolledData from "~/components/utilities/use-polled-data.js";
import ErrorBoundary from "~/shared-components/ErrorBoundary";
import { LiveGraphicScreenshot } from "./LiveGraphic";
import "maplibre-gl/dist/maplibre-gl.css";

const FALLBACK_MSG = "Fell back to a screenshot";

const getErrorTag = (props) =>
  `ElexComponent: ${props?.component || "component type missing"} / ${
    props?.id || "id missing"
  }`;

const reportError = ({ e, msg, props }) => {
  msg = `${getErrorTag(props)}: ${msg} b/c ${e.message}`;
  /* eslint-disable no-console */
  console.log(msg);
  console.error(e);
};

const isMapComponent = ({ component }) =>
  component === "ResultsMap" || component === "NationalMap";

const getMapConfig = (componentProps) => {
  // If this is a map component, we need to get the map config
  if (componentProps.component === "ResultsMap") {
    // Find the first mapPath in the list of races
    const mapPath = componentProps.races.find((race) => race.mapPath)?.mapPath;

    if (mapPath) {
      return parseMapConfig(mapPath, componentProps.date);
    }
  }
  if (componentProps.component === "NationalMap") {
    return parseMapConfig("nation/state/usa", componentProps.date);
  }

  return Promise.resolve({});
};

const getElectionComponent = (props, results) => {
  let ComponentClass = null;
  let propsForComponent = {};
  let error;
  try {
    [ComponentClass, propsForComponent] = resolveComponentProps(props, results);
  } catch (e) {
    // NOTE: Error reported below
    error = e;
  }

  if (!ComponentClass) {
    reportError({
      e: error || new Error("Missing ComponentClass"),
      msg: `1. ${FALLBACK_MSG}`,
      props
    });
    ComponentClass = LiveGraphicScreenshot;
    propsForComponent = props;
  }

  return <ComponentClass {...propsForComponent} />;
};

const ElectionWithUpdates = ({ results = {}, ...props }) => {
  const [mapConfig, setMapConfig] = useState();
  const fetchedResults = usePolledData({
    isSameContent: () => false,
    INTERVAL: 30000,
    fetchOnce: false,
    endpoint: get(props, "additions.endpoint")
  });

  const freshResults = get(fetchedResults, "latest");

  // lets create a state here so we can merge in freshResults if not null
  const [resultsData, setResultsData] = useState({
    ...results,
    ...(freshResults || {})
  });

  // if fresh results is not null, let's merge and sync the state
  useEffect(() => {
    setResultsData((prevState) => ({
      ...prevState,
      ...freshResults
    }));
  }, [freshResults]);

  try {
    if (!mapConfig) {
      getMapConfig(props).then(setMapConfig);
    }

    return props.component &&
      resultsData &&
      (!isMapComponent(props) || mapConfig) ? (
      getElectionComponent(
        {
          ...props,
          componentProps: {
            ...(props.componentProps || {}),
            loadingComponent: <LiveGraphicScreenshot {...props} />,
            mapConfig
          }
        },
        resultsData
      )
    ) : (
      <LiveGraphicScreenshot {...props} />
    );
  } catch (e) {
    reportError({ e, msg: `2. ${FALLBACK_MSG}`, props });
    return <LiveGraphicScreenshot {...props} />;
  }
};

const Election = ({
  useScreenshot = false,
  updateClientSide = false,
  // eslint-disable-next-line no-unused-vars
  showErrorMessage = false,
  ...props
}) => {
  const [mapConfig, setMapConfig] = useState();
  let electionComponent;
  let fallbackComponent = null;
  props = {
    ...props,
    updateClientSide
  };
  if (useScreenshot) {
    electionComponent = <LiveGraphicScreenshot {...props} />;
  } else {
    fallbackComponent = <LiveGraphicScreenshot {...props} />;
    const hasComponent = !!props?.component;
    const hasResults = !!props?.results;
    if (updateClientSide) {
      electionComponent =
        hasComponent && hasResults ? (
          <ElectionWithUpdates {...props} />
        ) : (
          fallbackComponent
        );
    } else {
      if (!mapConfig) {
        getMapConfig(props).then(setMapConfig);
      }

      electionComponent =
        hasComponent && hasResults && (!isMapComponent(props) || mapConfig)
          ? getElectionComponent(
              {
                ...props,
                componentProps: {
                  ...(props.componentProps || {}),
                  loadingComponent: fallbackComponent,
                  mapConfig
                }
              },
              props.results
            )
          : fallbackComponent;
    }
    if (electionComponent === fallbackComponent)
      reportError({
        e: new Error(`${!hasComponent ? "Component Missing" : "No Results"}`),
        msg: `3. ${FALLBACK_MSG}`,
        props
      });
  }

  try {
    return (
      <ErrorBoundary
        FallbackComponent={fallbackComponent}
        errorTag={getErrorTag(props)}
      >
        <div className="mb-xs live-graphic">{electionComponent}</div>
      </ErrorBoundary>
    );
  } catch (e) {
    reportError({ e, msg: `4. ${FALLBACK_MSG}`, props });
    return <div className="mb-xs live-graphic">{fallbackComponent}</div>;
  }
};

ElectionWithUpdates.propTypes = {
  component: PropTypes.string,
  races: PropTypes.array,
  results: PropTypes.object
};

Election.propTypes = {
  component: PropTypes.string,
  results: PropTypes.object,
  useScreenshot: PropTypes.bool,
  updateClientSide: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
  sources: PropTypes.string,
  sourcesClasses: PropTypes.string
};

export default Election;
