import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import get from "lodash.get";

async function fetchJSON({ endpoint, options }) {
  if (!endpoint) return undefined;
  // NOTE: endpoint is relative
  if (/^\//.test(endpoint)) {
    const path = get(document || {}, "location.pathname", undefined);
    // NOTE: path starts with /assembler/ but endpoint doesn't
    if (/^\/assembler\//.test(path) && !/^\/assembler\//.test(endpoint)) {
      endpoint = `/assembler${endpoint}`;
    }
  }
  try {
    const data = await fetch(endpoint, { ...options, cache: "no-store" });
    return data ? await data.json() : {};
  } catch (e) {
    return {};
  }
}

/**
 *
 * @param {content} object This is the original content supplied to the hook.
 * @param {isSameContent} function This is the function that tests your original content to the updated stuff.
 * @param {INTERVAL} number Time your updates will happen in. Default is 30 seconds.
 * @param {endpoint} string a custom endpoint
 */
const usePolledData = ({
  content: originalContent,
  // NOTE: if no payload, return true, which means don't replace existing (i.e. latest) content
  isSameContent = (latest, payload) => !payload,
  INTERVAL = 30000,
  fetchOnce = false,
  endpoint,
  options = {}
}) => {
  const mountedRef = useRef(false);
  // If an error is thrown, clear for polling
  const [startPolling, setStartPolling] = useState(false);

  const [pollState, dispatch] = useReducer(
    (state, action) => {
      return {
        ...state,
        latest: isSameContent(state.latest, action.payload)
          ? state.latest
          : action.payload
      };
    },
    {
      latest: originalContent || null,
      next: null
    }
  );

  const fetchData = useCallback(() => {
    if (endpoint) {
      try {
        fetchJSON({ endpoint, options }).then((content) => {
          if (mountedRef.current) {
            dispatch({ payload: content });
            // trigger polling to start since content is good
            if (!startPolling) setStartPolling(true);
          }
        });
      } catch (e) {
        // console.log(e);
        // polling never starts because the endpoint threw an error
      }
    }
  }, [endpoint, options, startPolling]);

  useEffect(() => {
    const fn = fetchOnce ? setTimeout : setInterval;
    const fnClear = fetchOnce ? clearTimeout : clearInterval;
    let timeoutOrInvervalId;

    if (startPolling) {
      // fetch once. no errors? keep fetchin!
      timeoutOrInvervalId = fn(async () => {
        try {
          const content = await fetchJSON({ endpoint, options });
          dispatch({ payload: content });
        } catch (e) {
          fnClear(timeoutOrInvervalId);
        }
      }, INTERVAL);
    }

    return () => {
      fnClear(timeoutOrInvervalId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startPolling]);

  useEffect(() => {
    mountedRef.current = true;

    // Reach out to the endpoint and fetch latest content.
    fetchData();

    return () => {
      mountedRef.current = false;
    };

    // Needs to be disabled or we get too much fetchin
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    acknowledge: () => dispatch({ type: "acknowledge" }),
    hasData: !!pollState.next,
    latest: pollState.latest
  };
};

export default usePolledData;
